import styled from 'styled-components';
import Link from 'next/link';

const StyledFooter = styled.footer`
  background-color: #000;
  padding: 1.5rem;
  color: white;
  margin-top: auto;

  .container {
    margin: 0 auto;
  }

  p {
    font-family: Roboto, sans-serif;
    color: #ffe86c;
  }
`;

const StyledFooterList = styled.ul`
  list-style: none;

  padding: 0px;

  & li {
    font-size: 1rem;
    padding-bottom: 1rem;

    &:nth-child(2) {
      & a {
        font-weight: bold;
      }
    }

    &:nth-child(3) {
      & a {
        color: #a614ce;
        font-weight: bold;
      }
    }

    &:last-child {
      padding-bottom: 0px;
    }

    & a {
      text-decoration: none;
      color: white;
      font-family: Roboto, sans-serif;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Footer = () => (
  <StyledFooter>
    {' '}
    <div className="container">
      <StyledFooterList>
        <li>
          <Link href="/">Home</Link>
        </li>
        <li>
          <Link href="https://minicastle.canny.io/changelog" target="_blank">
            🍔 What's New
          </Link>
        </li>
        <li>
          <Link href="/guides/homeowners-guide">2025 Homeowner's Guide</Link>
        </li>
        <li>
          <Link
            className="plausible-event-name=Docs+Click"
            href="https://docs.minicastle.app"
          >
            Help Center
          </Link>
        </li>
        <li>
          <Link href="/login">Sign Up / Log In</Link>
        </li>
        <li>
          <Link href="/terms">Terms & Conditions</Link> |{' '}
          <Link href="/privacy">Privacy Policy</Link>
        </li>
        <li>
          <Link href="/about">
            Made in L.A. 🏖️ 🍿 🌴 ⋅ ©2025 Shiny Buildings, Inc.
          </Link>
        </li>
      </StyledFooterList>
    </div>
  </StyledFooter>
);

export default Footer;
